import React, { Component, createRef } from 'react';
import authService from './api-authorization/AuthorizeService';
import { StockDetails } from './StockDetails';

export class Home extends Component {
    static displayName = Home.name;

    constructor(props) {
        super(props);
        const { state } = props.location;
        this.state = { items: [], text: '', stockInfo: state, stockData: null, loading: state ? true : false, errorMsg: '', errorType: 'none', activesuggestion: 0, showSelection: 'block' };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        this.debouncedSearch = this.debounce(this.doSearch).bind(this);
        this.fetchStockData = this.fetchStockData.bind(this);

        this.wrapperRef = createRef();
    }

    // Check for click outside of dropdown suggestions
    handleClickOutside = (event) => {
        if (this.wrapperRef.current && !this.wrapperRef.current.contains(event.target)) {
            this.setState({ showSelection: 'none' })
        }
        else {
            this.setState({ showSelection: 'block' })
            this.debouncedSearch();
        }
    }

    componentDidMount() {
        if (this.state.stockInfo) {
            this.doSelect(this.state.stockInfo);
        }
        document.addEventListener('mousedown', this.handleClickOutside)
    }

    debounce(func) {
        let timer;
        return function (...args) {
            const context = this;
            if (timer) clearTimeout(timer);
            timer = setTimeout(() => {
                timer = null;
                func.apply(context, args);
            }, 200);
        };
    };

    async handleChange(e) {
        e.preventDefault();
        await this.setState({ text: e.target.value, showSelection: 'block', activesuggestion: 0});

        if (this.state.text.length < 2) {
            this.setState({ errorMsg: 'Search term must be at least 2 characters', errorType: 'none', items: [] });
            return;
        }
        this.debouncedSearch();
    }

    async handleSubmit(e) {
        e.preventDefault();
        if (this.state.text.length < 2) {
            this.setState({ errorMsg: 'Search term must be at least 2 characters', errorType: 'none', items: [] });
            return;
        }
        if (this.state.items.length === 0) {
            return //Either no results or holding off until debounceSearch returns
        }
        await this.doSearch()
        if (this.state.items.length === 0) {
            return
        }
        this.setState({ stockInfo: null, stockData: null, loading: false, errorMsg: '', errorType: 'none', showSelection: 'none' });
        this.doSelect(this.state.items[this.state.activesuggestion]);
        await this.setState({ text: this.state.items[this.state.activesuggestion].name, activesuggestion: 0 })
    }

    async handleSelect(e) {
        var index = e.currentTarget.dataset.index || 0;
        var item = this.state.items[index];
        if (item) {
            this.doSelect(item);
        }
        await this.setState({text: item.name, activesuggestion: 0})
    }

    doSelect(item) {
        this.setState({ stockInfo: item, loading: true, stockData: null, showSelection: 'none' });
        this.fetchStockData(item);
    }

    renderError() {
        switch (this.state.errorType) {
            case 'error':
                return (
                    <div className="alert alert-dismissible alert-danger">
                        {this.state.errorMsg}
                    </div>);
            case 'info':
                return (
                    <div className="alert alert-dismissible alert-secondary">
                        {this.state.errorMsg}
                    </div>);
            default:
                return (<div />);
        }
    }

    onKeyDown = e => {

        let newActive;

        if (e.keyCode === 40 && this.state.activesuggestion < this.state.items.length - 1) {
            newActive = this.state.activesuggestion + 1;
            this.setState({ activesuggestion: newActive});
        }
        else if (e.keyCode === 38 && this.state.activesuggestion > 0) {
            newActive = this.state.activesuggestion - 1;
            this.setState({ activesuggestion: newActive});
        }
    }

    renderSuggestions() {
        let listComponent = (
            <ul className="form-group list-group" style={{ position: "absolute", backgroundColor:"#4e5d6c", zIndex: "2", marginTop: -10, display: this.state.showSelection}}>
                {this.state.items.map((item, index) => {
                    let className = "list-group-item list-group-item-action";

                    if (index === this.state.activesuggestion) {
                        className += " active"
                    }
                    return (
                        <li key={index} data-index={index} className={className} onClick={this.handleSelect} style={{ cursor: "pointer"}}>
                            <b>{item.name}</b> [{item.ricCode} - {item.isin}]</li>);
                })}
            </ul>
        );
        return listComponent;
    }

    render() {
        return (
            <div>
                <h3>ALBA Single Stock Viewer</h3>

                <form onSubmit={this.handleSubmit}>
                    <div className="form-group" ref={this.wrapperRef}>
                        <div className="input-group mb-3">
                            <input
                                id="search-text"
                                onChange={this.handleChange}
                                value={this.state.text}
                                type="text"
                                className="form-control"
                                placeholder="Stock name or ticker"
                                aria-label="Stock name or ticker"
                                aria-describedby="button-addon2"
                                autoComplete="off"
                                onKeyDown={this.onKeyDown}
                                />
                            <button className="btn btn-primary" type="submit" id="button-addon2"> Search</button>
                        </div>
                        { this.renderSuggestions() }
                    </div>
 
                </form>
                {this.renderError()}
                <StockDetails stockInfo={this.state.stockInfo} loading={this.state.loading} stockData={this.state.stockData} key={this.state.stockInfo?.instrumentID ?? 0} />
            </div>
        );
    }

    async doSearch() {
        if (this.state.text.length < 2) {
            this.setState({ errorMsg: 'Search term must be at least 2 characters', errorType: 'none', items: [] });
            return;
        }
        const token = await authService.getAccessToken();
        const response = await fetch('api/stockSearch?searchTerm=' + encodeURIComponent(this.state.text), {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        if (response.status < 200 || response.status >= 300) {
            this.setState({ errorMsg: 'There was an error running your search - please try again or contact support@3ai.co for assistance', errorType: 'error', items: [] });
            return;
        }
        const data = await response.json();
        if (data.length === 0) {
            this.setState({ errorMsg: 'Your search returned no results - please try again!', errorType: 'info', items: [] });
        } else {
            this.setState({ items: data, errorMsg: '', errorType: 'none'});
        }
    }

    async fetchStockData(stockInfo) {
        const token = await authService.getAccessToken();
        const response = await fetch('api/stockData?instrumentId=' + stockInfo.instrumentID, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        if (response.status < 200 || response.status >= 300) {
            this.setState({ errorMsg: 'There was an error fetching stock data - please try again or contact support@3ai.co for assistance', errorType: 'error', items: [] });
            return;
        }
        const data = await response.json();
        this.setState({ stockData: data, loading: false });
    }
}
