import React, { Component } from 'react';
import Plot from 'react-plotly.js';
import { Link } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import './react-tabs-custom.css';
import { useMediaQuery } from 'react-responsive';
import Select from 'react-select';

export class StockDetails extends Component {
    static displayName = StockDetails.name;
    static plotly_template = {
        data: {}, layout: { paper_bgcolor: "#20374c", plot_bgcolor: "#20374c", font: { color: "#ebebeb" }, xaxis: { color: "#ebebeb" }, yaxis: { color: "#ebebeb" } }
    };

    constructor(props) {
        super(props);
        this.state = { tabIndex: 0 };
    }

    Mobile = ({ children }) => {
        const isMobile = useMediaQuery({ maxWidth: 767 })
        return isMobile ? children : null
    }
    Default = ({ children }) => {
        const isNotMobile = useMediaQuery({ minWidth: 768 })
        return isNotMobile ? children : null
    }

    render() {
        if (!this.props.stockInfo) {
            return (<div/>);
        }
        if (this.props.loading) {
            return (<p> <em>Loading...</em></p>);
        }

        const options = [
            { value: 0, label: 'Factor Summary' }
        ].concat(this.props.stockData.forceTopFactors.columnNames.map((cn, index) => ({ value: index + 1, label: cn })));

        // NOTE: responsivity - currently minimal support, looks ok down to 975 wide. Plotly plots resize, gauges are fixed, next step is to
        // use react-responsive or similar to break at 975 and wrap gauges and plots, and probably come up with a replacement for the tabs.
        return (
            <div>
                {this.renderStockInfo()}
                <div style={{ clear: 'both' }}></div>
                <div>
                    <Tabs selectedIndex={this.state.tabIndex} onSelect={index => this.setState({ tabIndex: index })} style={{ height: 510 + 'px' }}>
                        <this.Default>
                            <TabList>
                                <Tab style={{ width: 117 + 'px' }} key="-1">Factor Summary</Tab>
                                {this.props.stockData.forceTopFactors.columnNames.map((cn, index) => (<Tab style={{ width: 117 + 'px' }} key={index}>{cn}</Tab>))}
                            </TabList>
                        </this.Default>
                        <this.Mobile>
                            <Select
                                options={options}
                                isSearchable={false}
                                value={options[this.state.tabIndex]}
                                onChange={value => this.setState({ tabIndex: value.value })}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                        ...theme.colors,
                                        primary: 'var(--bs-light)', // selected item background
                                        primary25: 'var(--bs-secondary)', // hover background
                                        neutral0: 'var(--bs-body-bg)', // background
                                        neutral20: 'var(--bs-gray-200)', // border, separator and down arrow
                                        neutral30: 'var(--bs-gray-300)', // border hover
                                        neutral40: 'var(--bs-gray-400)', // arrow hover
                                        neutral80: 'var(--bs-body-color)', // main item text
                                    }
                                })}
                            />
                        </this.Mobile>
                        <TabPanel key="-1">
                            <div style={{ width: 100 + '%' }}>
                                <this.Default>
                                    <div style={{ width: 50 + '%', float: 'left' }}>
                                        {this.renderBarPlot(
                                            this.props.stockData.forceTopFactors, 'Factor Force',
                                            (data) => {
                                                this.setState({ tabIndex: data.points[0].pointNumber + 1 })
                                            })}
                                    </div>
                                    <div style={{ width: 50 + '%', float: 'right' }}>
                                        {this.renderPiePlot(
                                            this.props.stockData.infTopFactors, 'Factor Influence',
                                            (data) => {
                                                this.setState({ tabIndex: data.points[0].pointNumber + 1 })
                                            })}
                                    </div>
                                </this.Default>
                                <this.Mobile>
                                    <div>
                                        {this.renderBarPlot(
                                            this.props.stockData.forceTopFactors, 'Factor Force',
                                            (data) => {
                                                this.setState({ tabIndex: data.points[0].pointNumber + 1 })
                                            })}
                                    </div>
                                    <div>
                                        {this.renderPiePlot(
                                            this.props.stockData.infTopFactors, 'Factor Influence',
                                            (data) => {
                                                this.setState({ tabIndex: data.points[0].pointNumber + 1 })
                                            })}
                                    </div>
                                </this.Mobile>
                            </div>
                            <div style={{ clear: 'both' }}></div>
                        </TabPanel>
                        {this.props.stockData.forceTopFactors.columnNames.map((cn, index) => (
                            <TabPanel key={index}>
                                <div style={{ width: 100 + '%' }}>
                                    <this.Default>
                                        <div style={{ width: 50 + '%', float: 'left' }}>
                                            {this.renderBarPlot(
                                                this.props.stockData.forceSubFactors[index], cn + ' - Force')}
                                        </div>
                                        <div style={{ width: 50 + '%', float: 'right' }}>
                                            {this.renderPiePlot(
                                                this.props.stockData.infSubFactors[index], cn + ' - Influence')}
                                        </div>
                                    </this.Default>
                                    <this.Mobile>
                                        <div>
                                            {this.renderBarPlot(
                                                this.props.stockData.forceSubFactors[index], cn + ' - Force')}
                                        </div>
                                        <div>
                                            {this.renderPiePlot(
                                                this.props.stockData.infSubFactors[index], cn + ' - Influence')}
                                        </div>
                                    </this.Mobile>
                                </div>
                                <div style={{ clear: 'both' }}></div>
                            </TabPanel>
                            ))}
                    </Tabs>
                </div>
            </div>
            );
    }

    renderStockInfo() {
        var reutersLink = "https://www.reuters.com/companies/" + this.props.stockInfo.ricCode;
        return (
            <div className="card text-white bg-dark mb-3">
                <h4 className="card-header">{this.props.stockInfo.name} [{this.props.stockInfo.ticker}]</h4>
                <div className="card-body" style={{ width: 100 + '%' }}>
                    <this.Default>
                        <div style={{ width: 30 + '%', float: 'left' }}>
                            <p className="card-text"><b>ISIN: </b>{this.props.stockInfo.isin}
                                <br /><b>Country: </b>{this.props.stockInfo.country}
                                <br /><b>Sector: </b>{this.props.stockInfo.sector}
                                <br /><b>Activity: </b>{this.props.stockInfo.activity}
                                <br /><a href={reutersLink} target="_blank"> Financials - Reuters</a>
                                <br /><Link to={{ pathname: '/search', stockInfo: this.props.stockInfo }}>Find similar stocks</Link>
                            </p>
                        </div>
                        <div style={{ width: 70 + '%', float: 'right' }}>
                            {this.renderGauges(this.props.stockData.albaScore, this.props.stockData.alphaScore, this.props.stockData.previousAlbaScore)}
                        </div>
                    </this.Default>
                    <this.Mobile>
                        <div>
                            {this.renderGauges(this.props.stockData.albaScore, this.props.stockData.alphaScore, this.props.stockData.previousAlbaScore)}
                        </div>
                        <div>
                            <p className="card-text"><b>ISIN: </b>{this.props.stockInfo.isin}
                                <br /><b>Country: </b>{this.props.stockInfo.country}
                                <br /><b>Sector: </b>{this.props.stockInfo.sector}
                                <br /><b>Activity: </b>{this.props.stockInfo.activity}
                                <br /><a href={reutersLink} target="_blank"> Financials - Reuters</a>
                                <br /><Link to={{ pathname: '/search', stockInfo: this.props.stockInfo }}>Find similar stocks</Link>
                            </p>
                        </div>
                    </this.Mobile>
                </div>
            </div>);
    }

    renderGauges(score, alpha, previousScore) {
        let alphaColour = alpha < -5 ? "orangered" : alpha < 5 ? "dimgrey" : "mediumseagreen";
        let scoreColour = score < 15 ? "orangered" : score < 85 ? "dimgrey" : "mediumseagreen";
        let rankHover = `This stock’s expected one year total return is greater than ${score.toFixed(1)}% of all stocks covered`;
        let alphaHover = `This stock is expected to deviate from the average of all stocks covered by ${alpha < 0 ? '-' : '+'}${alpha.toFixed(1)}% total return over the next year`;
        return (
            <div>
                <this.Default>
                    <div data-bs-toggle="tooltip" data-bs-placement="top" title={alphaHover} style={{ width: 50 + '%', float: 'left' }}>
                        <Plot
                            data={[
                                {
                                    type: "indicator",
                                    value: alpha,
                                    number: { valueformat: '.1f', suffix: '%' },
                                    mode: "gauge+number",
                                    gauge: {
                                        axis: { range: [-40, 40], tick0: -30, dtick: 20 },
                                        bar: { color: alphaColour },
                                        steps: [
                                            { range: [-40, -5], color: "rgba(255,0,0,0.3)" },
                                            { range: [-5, 5], color: "rgba(128,128,128,0.3)" },
                                            { range: [5, 40], color: "rgba(0,128,0,0.3)" }
                                        ]
                                    },
                                    domain: { row: 0, column: 0 },
                                    title: "ALBA Alpha Forecast"
                                }]}
                            layout={
                                {
                                    autosize: false,
                                    margin: { t: 40, b: 5, l: 0, r: 0, pad: 0 },
                                    width: 325,
                                    height: 150,
                                    template: StockDetails.plotly_template
                                }
                            }
                            config={{ displayModeBar: false }}
                        />
                    </div>
                    <div data-bs-toggle="tooltip" data-bs-placement="top" title={rankHover} style={{ width: 50 + '%', float: 'right' }}>
                        <Plot
                            data={[
                                {
                                    type: "indicator",
                                    value: score,
                                    number: { valueformat: '.1f', suffix: '' },
                                    mode: "gauge+number",
                                    gauge: {
                                        axis: { range: [0, 100], tick0: 0, dtick: 20 },
                                        bar: { color: scoreColour },
                                        steps: [
                                            { range: [0, 15], color: "rgba(255,0,0,0.3)" },
                                            { range: [15, 85], color: "rgba(128,128,128,0.3)" },
                                            { range: [85, 100], color: "rgba(0,128,0,0.3)" }
                                        ]
                                    },
                                    domain: { row: 0, column: 1 },
                                    title: "ALBA Rank Score"
                                }
                            ]}
                            layout={
                                {
                                    autosize: false,
                                    margin: { t: 40, b: 5, l: 0, r: 0, pad: 0 },
                                    width: 325,
                                    height: 150,
                                    template: StockDetails.plotly_template
                                }
                            }
                            config={{ displayModeBar: false }}
                        />
                    </div>
                </this.Default>
                <this.Mobile>
                    <div data-bs-toggle="tooltip" data-bs-placement="top" title={alphaHover}>
                        <Plot
                            data={[
                                {
                                    type: "indicator",
                                    value: alpha,
                                    number: { valueformat: '.1f', suffix: '%' },
                                    mode: "gauge+number",
                                    gauge: {
                                        axis: { range: [-40, 40], tick0: -30, dtick: 20 },
                                        bar: { color: alphaColour },
                                        steps: [
                                            { range: [-40, -5], color: "rgba(255,0,0,0.3)" },
                                            { range: [-5, 5], color: "rgba(128,128,128,0.3)" },
                                            { range: [5, 40], color: "rgba(0,128,0,0.3)" }
                                        ]
                                    },
                                    domain: { row: 0, column: 0 },
                                    title: "ALBA Alpha Forecast"
                                }]}
                            layout={
                                {
                                    autosize: false,
                                    margin: { t: 40, b: 5, l: 0, r: 0, pad: 0 },
                                    width: 325,
                                    height: 150,
                                    template: StockDetails.plotly_template
                                }
                            }
                            config={{ displayModeBar: false }}
                        />
                    </div>
                    <div data-bs-toggle="tooltip" data-bs-placement="top" title={rankHover}>
                        <Plot
                            data={[
                                {
                                    type: "indicator",
                                    value: score,
                                    number: { valueformat: '.1f', suffix: '' },
                                    mode: "gauge+number",
                                    gauge: {
                                        axis: { range: [0, 100], tick0: 0, dtick: 20 },
                                        bar: { color: scoreColour },
                                        steps: [
                                            { range: [0, 15], color: "rgba(255,0,0,0.3)" },
                                            { range: [15, 85], color: "rgba(128,128,128,0.3)" },
                                            { range: [85, 100], color: "rgba(0,128,0,0.3)" }
                                        ]
                                    },
                                    domain: { row: 0, column: 1 },
                                    title: "ALBA Rank Score"
                                }
                            ]}
                            layout={
                                {
                                    autosize: false,
                                    margin: { t: 40, b: 5, l: 0, r: 0, pad: 0 },
                                    width: 325,
                                    height: 150,
                                    template: StockDetails.plotly_template
                                }
                            }
                            config={{ displayModeBar: false }}
                        />
                    </div>
                </this.Mobile>
            </div>
        );
    }

    renderPiePlot(plotData, title, onClick = null) {
        return (
            <div>
                <this.Default>
                    <Plot
                        onClick={onClick}
                        data={[
                            {
                                type: 'pie',
                                labels: plotData.columnNames,
                                values: plotData.values,
                                hoverinfo: 'label'
                            }
                        ]}
                        layout={{
                            title: title,
                            autosize: true,
                            legend: { orientation: "v" },
                            template: StockDetails.plotly_template
                        }}
                        useResizeHandler={true}
                        config={{ displayModeBar: false, staticPlot: true }}
                        style={{
                            width: "100%", height: "100%"
                        }}
                    />
                </this.Default>
                <this.Mobile>
                    <Plot
                        onClick={onClick}
                        data={[
                            {
                                type: 'pie',
                                labels: plotData.columnNames,
                                values: plotData.values,
                                hoverinfo: 'label'
                            }
                        ]}
                        layout={{
                            title: title,
                            autosize: true,
                            legend: { orientation: "h" },
                            template: StockDetails.plotly_template
                        }}
                        useResizeHandler={true}
                        config={{ displayModeBar: false, staticPlot: true }}
                        style={{
                            width: "100%", height: "100%"
                        }}
                    />
                </this.Mobile>
            </div>
        );
    }

    renderBarPlot(plotData, title, onClick = null) {
        return (
            <Plot
                onClick={onClick}
                data={[
                    {
                        type: 'bar',
                        x: plotData.columnNames,
                        y: plotData.values,
                        hovertemplate: "%{x}: %{y:.2f}% <extra></extra>"
                    }
                ]}
                layout={{
                    title: title,
                    autosize: true,
                    template: StockDetails.plotly_template,
                    yaxis: { title: "Alpha (%)", fixedrange: true },
                    xaxis: { fixedrange: true }
                }}
                useResizeHandler={true}
                config={{ displayModeBar: false, scrollZoom: false }}
                style={{
                    width: "100%", height: "100%"
                }}
            />);
    }}