import React, { Component } from 'react';
import { WithContext as ReactTags } from 'react-tag-input';
import './react-tags-custom.css';
import { useMediaQuery } from 'react-responsive';
import DataGrid from 'react-data-grid';
import './react-data-grid-custom.css';

import authService from './api-authorization/AuthorizeService';

const KeyCodes = {
    comma: 188,
    enter: [10, 13],
};

const delimiters = [...KeyCodes.enter, KeyCodes.comma];

const columns = [
    { key: 'name', name: 'Name' },
    { key: 'isin', name: 'ISIN', width: 125 },
    { key: 'ticker', name: 'Ticker', width: 60 },
    { key: 'country', name: 'HQ Country', width: 100 },
    { key: 'exchange', name: 'Exchange' },
    { key: 'sector', name: 'Sector' },
    { key: 'activity', name: 'Activity' },
    {
        key: 'albaScore',
        name: 'ALBA Score',
        width: 115,
        formatter(props) {
            const value = props.row.albaScore;
            //const color = getColor(value);
            const color = value <= 15 ? 'orangered' : value >= 85 ? '#5cb85c' : 'grey';
            return (
                <>
                    <progress max={100} value={value} style={{ width: 50, accentColor: color }} /> {value.toFixed(2)}
                </>
            );
        }}
];

const columnsMobile = [
    { key: 'name', name: 'Name', width: 130 },
    {
        key: 'albaScore',
        name: 'ALBA Score',
        width: 115,
        formatter(props) {
            const value = props.row.albaScore;
            //const color = getColor(value);
            const color = value <= 15 ? 'orangered' : value >= 85 ? '#5cb85c' : 'grey';
            return (
                <>
                    <progress max={100} value={value} style={{ width: 50, accentColor: color }} /> {value.toFixed(2)}
                </>
            );
        }
    },
    { key: 'sector', name: 'Sector', width: 125 },
    { key: 'activity', name: 'Activity', width: 125 },
    { key: 'isin', name: 'ISIN', width: 125 },
    { key: 'ticker', name: 'Ticker', width: 60 },
    { key: 'country', name: 'HQ Country', width: 100 },
    { key: 'exchange', name: 'Exchange' },
];

export class Search extends Component {
    static displayName = Search.name;

    Mobile = ({ children }) => {
        const isMobile = useMediaQuery({ maxWidth: 767 })
        return isMobile ? children : null
    }
    Default = ({ children }) => {
        const isNotMobile = useMediaQuery({ minWidth: 768 })
        return isNotMobile ? children : null
    }

    constructor(props) {
        console.log('constructor()');
        super(props);

        const { state, stockInfo } = props.location;
        if (state) {
            this.state = state;
        } else {
            this.state = {
                stockInfo: stockInfo,
                items: [],
                sortedRows: [],
                countries: [],
                sectors: [],
                activities: [],
                selectedCountries: [],
                selectedSectors: [],
                selectedActivities: [],
                loading: false,
                errorMsg: '',
                errorType: 'none',
                best: true,
                count: "10",
                min_mcap: null,
                max_mcap: null,
                min_adv: null,
                max_adv: null,
                sectorInclude: true,
                countryInclude: true,
                activityInclude: true,
                sortColumns: []
            };
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChangeCount = this.handleChangeCount.bind(this);
        this.handleToggle = this.handleToggle.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleAddition = this.handleAddition.bind(this);
        this.handleDeleteSector = this.handleDeleteSector.bind(this);
        this.handleAdditionSector = this.handleAdditionSector.bind(this);
        this.handleDeleteActivity = this.handleDeleteActivity.bind(this);
        this.handleAdditionActivity = this.handleAdditionActivity.bind(this);
        this.handleNumber = this.handleNumber.bind(this);
        this.onSortColumnsChange = this.onSortColumnsChange.bind(this);
        this.onRowClick = this.onRowClick.bind(this);
    }

    componentDidMount() {
        console.log('componentDidMount()');
        this.getCountries();
        this.getSectors();
        this.getActivities();
    }

    handleDelete(i) {
        const { selectedCountries } = this.state;
        this.setState({
            selectedCountries: selectedCountries.filter((tag, index) => index !== i),
        });
    }

    handleAddition(tag) {
        if (tag.id !== tag.text) {
            // filters out newly created items
            this.setState(state => ({ selectedCountries: [...state.selectedCountries, tag] }));
        }
    }

    handleDeleteSector(i) {
        const { selectedSectors } = this.state;
        this.setState({
            selectedSectors: selectedSectors.filter((tag, index) => index !== i),
        });
    }

    handleAdditionSector(tag) {
        if (tag.id !== tag.text) {
            // filters out newly created items
            this.setState(state => ({ selectedSectors: [...state.selectedSectors, tag] }));
        }
    }

    handleDeleteActivity(i) {
        const { selectedActivities } = this.state;
        this.setState({
            selectedActivities: selectedActivities.filter((tag, index) => index !== i),
        });
    }

    handleAdditionActivity(tag) {
        if (tag.id !== tag.text) {
            // filters out newly created items
            this.setState(state => ({ selectedActivities: [...state.selectedActivities, tag] }));
        }
    }

    handleChangeCount(e) {
        this.setState({ count: e.target.value });
    }

    handleToggle(e) {
        var item = e.target.name;
        this.setState({ [item]: !this.state[item] });
    }

    handleNumber(e) {
        var item = e.target.id;
        var value = e.target.value ? Number(e.target.value) : null;
        this.setState({ [item]: value });
    }

    onSortColumnsChange(cols) {
        this.setState({ sortColumns: cols });
        this.sortRows(this.state.items, cols);
    }

    sortRows(items, cols) {
        if (cols.length === 0 ||
            items.length === 0) {
            this.setState({ sortedRows: items });
            return;
        }
        const sortedRows = [...items];
        sortedRows.sort((a, b) => {
            for (const sort of cols) {
                const comparator = this.getComparator(sort.columnKey);
                const compResult = comparator(a, b);
                if (compResult !== 0) {
                    return sort.direction === 'ASC' ? compResult : -compResult;
                }
            }
            return 0;
        });
        this.setState({ sortedRows: sortedRows });
    }

    handleSubmit(e) {
        e.preventDefault();
        //if (this.state.text.length < 2) {
        //    this.setState({ errorMsg: 'Search term must be at least 2 characters', errorType: 'error', items: [] });
        //    return;
        //}
        this.setState({ loading: false, errorMsg: '', errorType: 'none' })
        this.doSearch();
    }

    renderError() {
        switch (this.state.errorType) {
            case 'error':
                return (
                    <div className="alert alert-dismissible alert-danger">
                        {this.state.errorMsg}
                    </div>);
            case 'info':
                return (
                    <div className="alert alert-dismissible alert-secondary">
                        {this.state.errorMsg}
                    </div>);
            default:
                return (<div />);
        }
    }

    render() {
        console.log('render()');
        return (
            <div>
                <h3>ALBA Search</h3>
                <form onSubmit={this.handleSubmit} autoComplete="off">
                    <div className="container">
                        <div className="row align-items-end">
                            <div className="form-group col-sm-7">
                                <label htmlFor="countries">Countries</label>
                                <ReactTags tags={this.state.selectedCountries}
                                    suggestions={this.state.countries}
                                    handleDelete={this.handleDelete}
                                    handleAddition={this.handleAddition}
                                    placeholder='Type and press enter'
                                    inline={true}
                                    id='countries'
                                    inputFieldPosition='inline'
                                    inputProps={{ autoComplete: "new-password" }}
                                    delimiters={delimiters}
                                    allowDragDrop={false}
                                    autocomplete={true}
                                    autofocus={true}
                                    allowUnique={true} />
                            </div>
                            <div className="col-sm-2">
                                <div className="btn-group" role="group" aria-label="include/exclude">
                                    <input type="radio" className="btn-check" name="countryInclude" id="btnradio1Country" autoComplete="off" checked={this.state.countryInclude} onChange={this.handleToggle} />
                                    <label className="btn btn-outline-success" htmlFor="btnradio1Country">Include</label>
                                    <input type="radio" className="btn-check" name="countryInclude" id="btnradio2Country" autoComplete="off" checked={!this.state.countryInclude} onChange={this.handleToggle} />
                                    <label className="btn btn-outline-danger" htmlFor="btnradio2Country">Exclude</label>
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-end mt-2">
                            <div className="form-group col-sm-7">
                                <label htmlFor="sectors">Sectors</label>
                                <ReactTags tags={this.state.selectedSectors}
                                    suggestions={this.state.sectors}
                                    handleDelete={this.handleDeleteSector}
                                    handleAddition={this.handleAdditionSector}
                                    placeholder='Type and press enter'
                                    inline={true}
                                    id='sectors'
                                    inputFieldPosition='inline'
                                    inputProps={{ autoComplete: "off" }}
                                    delimiters={delimiters}
                                    allowDragDrop={false}
                                    autocomplete={true}
                                    autofocus={false}
                                    allowUnique={true} />
                            </div>
                            <div className="col-sm-2">
                                <div className="btn-group" role="group" aria-label="include/exclude">
                                    <input type="radio" className="btn-check" name="sectorInclude" id="btnradio1Sec" autoComplete="off" checked={this.state.sectorInclude} onChange={this.handleToggle} />
                                    <label className="btn btn-outline-success" htmlFor="btnradio1Sec">Include</label>
                                    <input type="radio" className="btn-check" name="sectorInclude" id="btnradio2Sec" autoComplete="off" checked={!this.state.sectorInclude} onChange={this.handleToggle} />
                                    <label className="btn btn-outline-danger" htmlFor="btnradio2Sec">Exclude</label>
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-end mt-2">
                            <div className="form-group col-sm-7">
                                <label htmlFor="activities">Activities</label>
                                <ReactTags tags={this.state.selectedActivities}
                                    suggestions={this.state.activities}
                                    handleDelete={this.handleDeleteActivity}
                                    handleAddition={this.handleAdditionActivity}
                                    placeholder='Type and press enter'
                                    inline={true}
                                    id='activities'
                                    inputFieldPosition='inline'
                                    inputProps={{ autoComplete: "off" }}
                                    delimiters={delimiters}
                                    allowDragDrop={false}
                                    autocomplete={true}
                                    autofocus={false}
                                    allowUnique={true} />
                            </div>
                            <div className="col-sm-2">
                                <div className="btn-group" role="group" aria-label="include/exclude">
                                    <input type="radio" className="btn-check" name="activityInclude" id="btnradio1Act" autoComplete="off" checked={this.state.activityInclude} onChange={this.handleToggle} />
                                    <label className="btn btn-outline-success" htmlFor="btnradio1Act">Include</label>
                                    <input type="radio" className="btn-check" name="activityInclude" id="btnradio2Act" autoComplete="off" checked={!this.state.activityInclude} onChange={this.handleToggle} />
                                    <label className="btn btn-outline-danger" htmlFor="btnradio2Act">Exclude</label>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-sm-4 form-group">
                                <label htmlFor="mcap_min" className="form-label">Market Cap ($M)</label>
                                <div className="form-group">
                                    <div className="input-group">
                                        <span className="input-group-text">From </span>
                                        <input
                                            id="mcap_min"
                                            onChange={this.handleNumber}
                                            value={this.state.mcap_min}
                                            type="number"
                                            step="any"
                                            className="form-control"
                                            placeholder="Minimum"
                                            autoComplete="new-password"
                                            aria-label="Minimum market cap" />
                                        <span className="input-group-text"> To </span>
                                        <input
                                            id="mcap_max"
                                            onChange={this.handleNumber}
                                            value={this.state.mcap_max}
                                            type="number"
                                            step="any"
                                            className="form-control"
                                            placeholder="Maximum"
                                            autoComplete="new-password"
                                            aria-label="Maximum market cap" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-4 form-group">
                                <label htmlFor="adv_min" className="form-label">ADV ($M)</label>
                                <div className="form-group">
                                    <div className="input-group">
                                        <span className="input-group-text">From </span>
                                        <input
                                            id="adv_min"
                                            onChange={this.handleNumber}
                                            value={this.state.adv_min}
                                            type="number"
                                            step="any"
                                            className="form-control"
                                            placeholder="Minimum"
                                            autoComplete="new-password"
                                            aria-label="Minimum ADV" />
                                        <span className="input-group-text"> To </span>
                                        <input
                                            id="adv_max"
                                            onChange={this.handleNumber}
                                            value={this.state.adv_max}
                                            type="number"
                                            step="any"
                                            className="form-control"
                                            placeholder="Maximum"
                                            autoComplete="new-password"
                                            aria-label="Maximum ADV" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-end mt-2">
                            <div className="col-sm-3 form-group">
                                <label htmlFor="bestworst" className="form-label">Find</label>
                                <div className="form-group">
                                    <div className="input-group">
                                        <div className="btn-group" role="group" aria-label="best vs worst" id="bestworst">
                                            <input type="radio" className="btn-check" name="best" id="btnradio1Best" autoComplete="off" checked={this.state.best} onChange={this.handleToggle} />
                                            <label className="btn btn-outline-success" htmlFor="btnradio1Best">Best</label>
                                            <input type="radio" className="btn-check" name="best" id="btnradio2Best" autoComplete="off" checked={!this.state.best} onChange={this.handleToggle} />
                                            <label className="btn btn-outline-danger" htmlFor="btnradio2Best">Worst</label>
                                        </div>
                                        <this.Mobile>
                                            <select className="form-select" value={this.state.count} onChange={this.handleChangeCount} style={{ height: 34 + 'px' }}>
                                                <option value="10">10</option>
                                                <option value="25">25</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                            </select>
                                        </this.Mobile>
                                        <this.Default>
                                            <select className="form-select" value={this.state.count} onChange={this.handleChangeCount} style={{ height: 38 + 'px' }}>
                                                <option value="10">10</option>
                                                <option value="25">25</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                            </select>
                                        </this.Default>
                                        <span className="input-group-text"> Stocks </span>
                                        <this.Mobile>
                                            <div className="col-sm-1">
                                                <button className="btn btn-primary" type="submit" id="button-submit">Search</button>
                                            </div>
                                        </this.Mobile>
                                    </div>
                                </div>
                            </div>
                            <this.Default>
                                <div className="col-sm-1">
                                    <button className="btn btn-primary" type="submit" id="button-submit">Search</button>
                                </div>
                            </this.Default>
                        </div>
                    </div>
                </form>
                {this.renderError()}
                {this.renderGrid()}
            </div>
        );
    }

    rowKeyGetter(row) {
        return row.instrumentID;
    }

    onRowClick(row, column) {
        console.log('onRowClick');
        this.props.history.replace({
            pathname: '/search',
            state: this.state
        });
        this.props.history.push({
            pathname: '/',
            state: row
        });
    }

    getComparator(sortColumn) {
        switch (sortColumn) {
            case 'albaScore':
                return (a, b) => {
                    return a[sortColumn] - b[sortColumn];
                };
            default:
                return (a, b) => {
                    return a[sortColumn].localeCompare(b[sortColumn]);
                };
        }
    }

    renderGrid() {
        if (this.state.items && this.state.items.length > 0) {
            return (
                <div>
                    <this.Mobile>
                        <DataGrid
                            columns={columnsMobile}
                            rows={this.state.sortedRows}
                            rowKeyGetter={this.rowKeyGetter}
                            defaultColumnOptions={{
                                sortable: true,
                                resizable: true
                            }}
                            sortColumns={this.state.sortColumns}
                            onSortColumnsChange={this.onSortColumnsChange}
                            onRowClick={this.onRowClick}
                            className='rdg-dark'
                        />
                    </this.Mobile>
                    <this.Default>
                        <DataGrid
                            columns={columns}
                            rows={this.state.sortedRows}
                            rowKeyGetter={this.rowKeyGetter}
                            defaultColumnOptions={{
                                sortable: true,
                                resizable: true
                            }}
                            sortColumns={this.state.sortColumns}
                            onSortColumnsChange={this.onSortColumnsChange}
                            onRowClick={this.onRowClick}
                            className='rdg-dark'
                        />
                    </this.Default>
                </div>
            );
        }
        return (<div/>);
    }

    async doSearch(state) {
        state = state || this.state;
        const queryParams = {
            top: state.best,
            count: state.count,
            countries: state.selectedCountries.map(sc => (sc.id)),
            countriesInclude: state.countryInclude,
            sectors: state.selectedSectors.map(ss => (ss.id)),
            sectorsInclude: state.sectorInclude,
            activities: state.selectedActivities.map(sa => (sa.id)),
            activitiesInclude: state.activityInclude,
            minMarketCap: state.mcap_min,
            maxMarketCap: state.mcap_max,
            minAdv: state.adv_min && (state.adv_min * 25000000),
            maxAdv: state.adv_max && (state.adv_max * 25000000)
        };

        const token = await authService.getAccessToken();
        const response = await fetch('api/stockSearch', {
            method: 'POST',
            body: JSON.stringify(queryParams),
            headers: !token ? { 'Accept': 'application/json' } : { 'Authorization': `Bearer ${token}`, 'Accept': 'application/json', 'Content-Type':'application/json' }
        });
        if (response.status < 200 || response.status >= 300) {
            this.setState({ errorMsg: 'There was an error running your search - please try again or contact support@3ai.co for assistance', errorType: 'error', items: [] });
            return;
        }
        const data = await response.json();
        if (data.length === 0) {
            this.setState({ errorMsg: 'Your search returned no results - please try again!', errorType: 'info', items: [] });
        } else {
            this.setState({ items: data });
            this.sortRows(data, this.state.sortColumns);
        }
    }

    async getCountries() {
        const token = await authService.getAccessToken();
        const response = await fetch('api/metadata/countries', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        console.log('Setting countries');
        //this.setState({ countries: data.map(country => ({ id: String(country.countryId), text: country.name })) });
        this.setState((state, props) => {
            const newState = {
                countries: data.map(country => ({ id: String(country.countryId), text: country.name })),
                sectors: state.sectors,
                activities: state.activities,
                stockInfo: state.stockInfo
            }
            this.checkStockInfo(newState);
            return newState;
        });
    }

    async getSectors() {
        const token = await authService.getAccessToken();
        const response = await fetch('api/metadata/sectors', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        console.log('Setting sectors');
        //this.setState({ sectors: data.map(sector => ({ id: String(sector.sectorId), text: sector.name })) });

        this.setState((state, props) => {
            const newState = {
                sectors: data.map(sector => ({ id: String(sector.sectorId), text: sector.name })),
                activities: state.activities,
                countries: state.countries,
                stockInfo: state.stockInfo
            };
            this.checkStockInfo(newState);
            return newState;
        });
    }

    async getActivities() {
        const token = await authService.getAccessToken();
        const response = await fetch('api/metadata/activities', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        console.log('Setting activities');

        this.setState((state, props) => {
            const newState = {
                activities: data.map(activity => ({ id: String(activity.activityId), text: activity.name })),
                sectors: state.sectors,
                countries: state.countries,
                stockInfo: state.stockInfo
            };
            this.checkStockInfo(newState);
            return newState;
        });
    }

    checkStockInfo(state) {
        console.log('checkStockInfo()');
        if (state.stockInfo &&
            state.countries && state.countries.length > 0 &&
            state.sectors && state.sectors.length > 0 &&
            state.activities && state.activities.length > 0) {
            console.log('checkStockInfo() - setting stock info');
            // find the country and sector, populate everything else and search
            const country = state.countries.find(c => c.text === state.stockInfo.country);
            const sector = state.sectors.find(s => s.text === state.stockInfo.sector);
            const activity = state.activities.find(a => a.text === state.stockInfo.activity);
            state.selectedSectors = [sector];
            state.selectedCountries = [country];
            state.selectedActivities = [activity];
            state.best = true;
            state.count = "10";
            state.min_mcap= null;
            state.max_mcap = null;
            state.min_adv = null;
            state.max_adv = null;
            state.sectorInclude = true;
            state.countryInclude = true;
            state.activityInclude = true;
            this.doSearch(state);
        }
    }
}
