import React, { Component } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { LoginMenu } from './api-authorization/LoginMenu';
import { useMediaQuery } from 'react-responsive';
import './NavMenu.css';

export class NavMenu extends Component {
  static displayName = NavMenu.name;

  constructor (props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true
    };
  }

  toggleNavbar () {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

    Mobile = ({ children }) => {
        const isMobile = useMediaQuery({ maxWidth: 575 })
        return isMobile ? children : null
    }
    Default = ({ children }) => {
        const isNotMobile = useMediaQuery({ minWidth: 576 })
        return isNotMobile ? children : null
    }

    render() {
        // There must be a better way to get the mobile menu to close after clicking a link - looks like the React-Bootstrap
        // project has a collapseOnSelect property of Navbar that should do it, but this project is using reactstrap that
        // doesn't have that.  Use of react responsive here is clunky, would be better to have a toggleNavbarMobile function
        // that does teh media query and toggles if mobile only, and single render pathway...
    return (
      <header>
        <Navbar className="navbar-expand-sm navbar-toggleable-sm navbar-dark bg-dark border-bottom box-shadow mb-3">
                <Container>
                    <NavbarBrand tag={Link} className="navbar-brand" to="/"><img src="logo.png" /></NavbarBrand>
            <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
            <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
              <ul className="navbar-nav flex-grow">
                <NavItem>
                    <this.Mobile>
                        <NavLink tag={Link} className="nav-link" to="/" onClick={this.toggleNavbar}>Home</NavLink>
                    </this.Mobile>
                    <this.Default>
                        <NavLink tag={Link} className="nav-link" to="/">Home</NavLink>
                    </this.Default>
                </NavItem>
                <NavItem>
                    <this.Mobile>
                        <NavLink tag={Link} className="nav-link" to="/search" onClick={this.toggleNavbar}>Search</NavLink>
                    </this.Mobile>
                    <this.Default>
                        <NavLink tag={Link} className="nav-link" to="/search">Search</NavLink>
                    </this.Default>
                </NavItem>
                <LoginMenu>
                </LoginMenu>
              </ul>
            </Collapse>
          </Container>
        </Navbar>
      </header>
    );
  }
}
